<template>
    <div class="main">
        <!-- 右侧主体 -->
         <el-tag
          @click="handlerJumpTags(tag.path)"
          @close="handlerColseTag(index)"
          v-for="(tag,index) in tags"
          :key="tag.path"
          :closable="tag.path!='/home'"
          size="medium"
          :effect="$route.path===tag.path ? 'dark' : 'plain' "
          >
          {{tag.title}}
        </el-tag>
        <router-view v-if="isRouterAlive"/>
    </div>
</template>

<script>
export default {
  name: 'my-main',
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isRouterAlive: true
    }
  },
  computed: {
    tags () {
      return this.$store.getters.tags
    }
  },
  watch: {
    $route: {
      handler (newRouter) {
        this.handlerAddTag()
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    // 通过声明reload方法，控制router-view的显示或隐藏，从而控制页面的再次加载
    reload () {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    },
    // 添加tags
    handlerAddTag () {
      const path = this.$route.path
      const title = this.$route.meta.title
      const newTag = {
        path, title
      }
      // console.log(newTag, 'zhugaga')
      if (newTag.path === '/markingDetails' || newTag.path === '/BusinessCard' || newTag.path === '/SMSNumberVerification' || newTag.path === '/announcement/listOne') {
        return
      }
      this.$store.dispatch('tags/addTag', newTag)
    },
    // 删除tags
    handlerColseTag (index) {
      // console.log(this.tags.length - 1, '看看长度')
      // console.log(index, 'index')
      if (!index) return
      this.$store.dispatch('tags/removeTag', index)
      // const path = this.tags[index - 1].path
      // if (this.$route.path === path) return
      // if (index === this.tags.length - 1) {
      //   this.$router.push(path)
      // }
    },
    // 点击tags跳转
    handlerJumpTags (path) {
      this.$router.push(path)
    }
  }
}
</script>

<style lang="scss" scoped>
.main{
  height: 100%;
}
.el-tag{
  cursor: pointer;
  margin-bottom: 20px;
  margin-left: 8px;
}

// tag
.el-tag--dark{
  background-color: #2d3a4b;
  border-color: black;
}
.el-tag--plain{
  border-color: #2d3a4b;
  color: #2d3a4b;
}
::v-deep .el-tag--plain .el-tag__close{
  color: #2d3a4b ;
}
::v-deep .el-tag--plain .el-icon-close:hover{
  background-color: #2d3a4b;
  color: #fff;
}
::v-deep .el-tag--dark .el-tag__close {
    color: #fff;
}
::v-deep .el-tag--dark .el-tag__close:hover {
    color: #2d3a4b;
    background-color: #fff;
}
</style>
