import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import './storage/index.scss'
import './permission.js'
// 图片放大组件
import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
import 'vue-directive-image-previewer/dist/assets/style.css'

// 富文本编辑器
import VueQuillEditor from 'vue-quill-editor'

// 导航收缩字体图标隐藏
import Fragment from 'vue-fragment'

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

// 超出隐藏文字
import * as filters from '@/utils/BeyondHidden'

import JsonExcel from 'vue-json-excel'
Vue.use(Fragment.Plugin)
Vue.component('downloadExcel', JsonExcel)
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})
Vue.use(VueDirectiveImagePreviewer)

Vue.use(ElementUI)
Vue.config.productionTip = false

// vue自定义指令
Vue.directive('permission', {
  inserted: function (el, binding, vnode) {
    // 获取后台所返回按钮权限列表
    const list = store.getters.permission
    // 获取当前按钮所绑定的权限
    const value = binding.value
    // 判断按钮所绑定的权限是否在后台所返回的按钮权限列表中
    const flag = list.includes(value)

    if (!flag) {
      // 如果不存在，则移除按钮
      el.style = 'display:none'
      setTimeout(() => {
        el.parentNode && el.parentNode.removeChild(el)
      }, 0)
    }
  }
})

Vue.use(VueQuillEditor /* { default global options } */)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
