
export default {
  namespaced: true,
  state: {
    isSubmenu: true
  },
  mutations: {
    SET_SUBMENU (state, bollean) {
      state.isSubmenu = bollean
    }
  }
}
