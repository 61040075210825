<template>
    <div>
        <template v-for="item in menu">
            <!-- 不包含子菜单的“一级菜单” -->
                <el-menu-item :index="'/'+item.url" :key="item.id"  v-if="!item.childlist && item.ismenu==='1' &&item.status==='1'" @click="addMenuId(item.id)">
                    <i :class="item.icon"></i>
                    {{ item.title }}
                </el-menu-item>

            <!-- 包含子菜单的“一级菜单” -->
            <el-submenu :index="item.id" v-show="item.ismenu==='1' && item.status==='1'" :key="item.id"  v-else>
                <template slot="title">
                  <i :class="item.icon"></i>
                    <span>{{ item.title }}</span>
                </template>
                <!-- 循环渲染“二级菜单” -->
                <el-menu-item :index="'/'+subItem.url" v-for="subItem in item.childlist" :key="subItem.id" v-show="subItem.ismenu==='1' && subItem.status==='1'" @click="addMenuId(subItem.id)">
                <i :class="subItem.icon"></i>
                   {{ subItem.title }}
                </el-menu-item>
            </el-submenu>
        </template>
    </div>
</template>

<script>
import { ObtainUserPermissions } from '@/api/ObtainUserPermissions'

export default {
  name: 'my-submenu',
  data () {
    return {
      menu: []
    }
  },
  created () {
    this.getObtainUserPermissions()
  },
  methods: {
    // 获取权限左侧接口
    async getObtainUserPermissions () {
      const { data: res } = await ObtainUserPermissions()
      // console.log(res.data.info, '权限查看')
      if (res.data === null) {
        this.$message('token已过期,请您重新登录')
        localStorage.removeItem('token')
        this.$router.push('/login')
        return
      }
      // this.menu = res.data.info
      this.menu = [
        {
          id: '1000',
          title: '号码来电名片',
          icon: 'el-icon-user-solid',
          url: 'order',
          level: '1',
          status: '1',
          pid: '0',
          ismenu: '1',
          update_time: '1673313470',
          create_time: '1668496659',
          childlist: [
            {
              id: '1700',
              title: '订单列表',
              icon: 'el-icon-user',
              url: 'order/orderList',
              level: '2',
              status: '1',
              pid: '1',
              ismenu: '1',
              update_time: '1673313483',
              create_time: null,
              childlist: [
                {
                  id: '2',
                  title: '添加角色',
                  icon: null,
                  url: 'role/role_add',
                  level: '3',
                  status: '1',
                  pid: '17',
                  ismenu: '0',
                  update_time: '1669259542',
                  create_time: '1668496659'
                },
                {
                  id: '3',
                  title: '角色删除',
                  icon: null,
                  url: 'role/del_role',
                  level: '3',
                  status: '2',
                  pid: '17',
                  ismenu: '0',
                  update_time: '1669259563',
                  create_time: '1668496659'
                },
                {
                  id: '18',
                  title: '修改角色',
                  icon: null,
                  url: 'role/update_role',
                  level: '3',
                  status: '2',
                  pid: '17',
                  ismenu: '0',
                  update_time: '1670289261',
                  create_time: null
                }
              ]
            },
            {
              id: '1701',
              title: '客户展示',
              icon: 'el-icon-user',
              url: 'order/customList',
              level: '2',
              status: '1',
              pid: '1',
              ismenu: '1',
              update_time: '1673313483',
              create_time: null
            },
            {
              id: '1702',
              title: '后台数据管理',
              icon: 'el-icon-user',
              url: 'order/dataList',
              level: '2',
              status: '1',
              pid: '1',
              ismenu: '1',
              update_time: '1673313483',
              create_time: null
            }
          ]
        },
        {
          id: '1',
          title: '角色管理',
          icon: 'el-icon-user-solid',
          url: 'role',
          level: '1',
          status: '1',
          pid: '0',
          ismenu: '1',
          update_time: '1673313470',
          create_time: '1668496659',
          childlist: [
            {
              id: '17',
              title: '角色列表',
              icon: 'el-icon-user',
              url: 'role/roleList',
              level: '2',
              status: '1',
              pid: '1',
              ismenu: '1',
              update_time: '1673313483',
              create_time: null,
              childlist: [
                {
                  id: '2',
                  title: '添加角色',
                  icon: null,
                  url: 'role/role_add',
                  level: '3',
                  status: '1',
                  pid: '17',
                  ismenu: '0',
                  update_time: '1669259542',
                  create_time: '1668496659'
                },
                {
                  id: '3',
                  title: '角色删除',
                  icon: null,
                  url: 'role/del_role',
                  level: '3',
                  status: '2',
                  pid: '17',
                  ismenu: '0',
                  update_time: '1669259563',
                  create_time: '1668496659'
                },
                {
                  id: '18',
                  title: '修改角色',
                  icon: null,
                  url: 'role/update_role',
                  level: '3',
                  status: '2',
                  pid: '17',
                  ismenu: '0',
                  update_time: '1670289261',
                  create_time: null
                }
              ]
            }
          ]
        },
        {
          id: '5',
          title: '号码操作列表',
          icon: 'el-icon-search',
          url: 'web_api/batch_unique_index',
          level: '1',
          status: '1',
          pid: '0',
          ismenu: '1',
          update_time: '1673335906',
          create_time: '1668498943',
          childlist: [
            {
              id: '4',
              title: '标记查询',
              icon: 'el-icon-edit',
              url: 'web_api/sign_search',
              level: '2',
              status: '1',
              pid: '5',
              ismenu: '1',
              update_time: '1673313430',
              create_time: '1668498943'
            },
            {
              id: '6',
              title: '批量导入',
              icon: 'el-icon-folder-add',
              url: 'web_api/add_tel',
              level: '3',
              status: '1',
              pid: '5',
              ismenu: '1',
              update_time: '1673313445',
              create_time: '1668498943'
            },
            {
              id: '25',
              title: 'ov标记导入',
              icon: 'el-icon-coordinate',
              url: 'web_api/ov_sign_search',
              level: '2',
              status: '1',
              pid: '5',
              ismenu: '1',
              update_time: '1675058329',
              create_time: '1669859941',
              childlist: [
                {
                  id: '26',
                  title: '导入号码',
                  icon: null,
                  url: 'web_api/ov_add_tel',
                  level: '3',
                  status: '1',
                  pid: '25',
                  ismenu: '0',
                  update_time: '1669859984',
                  create_time: '1669859984'
                }
              ]
            }
          ]
        },
        {
          id: '7',
          title: '权限管理',
          icon: 'el-icon-s-tools',
          url: 'access',
          level: '1',
          status: '1',
          pid: '0',
          ismenu: '1',
          update_time: '1673313376',
          create_time: null,
          childlist: [
            {
              id: '8',
              title: '权限列表',
              icon: 'el-icon-s-order',
              url: 'role/accessList',
              level: '2',
              status: '1',
              pid: '7',
              ismenu: '1',
              update_time: '1673313391',
              create_time: null,
              childlist: [
                {
                  id: '9',
                  title: '添加权限',
                  icon: null,
                  url: 'permission/addition',
                  level: '3',
                  status: '1',
                  pid: '8',
                  ismenu: '0',
                  update_time: '1670289909',
                  create_time: null
                },
                {
                  id: '10',
                  title: '修改权限',
                  icon: null,
                  url: 'permission/modification',
                  level: '3',
                  status: '1',
                  pid: '8',
                  ismenu: '0',
                  update_time: '1670290023',
                  create_time: null
                },
                {
                  id: '11',
                  title: '删除权限',
                  icon: null,
                  url: 'permission/deletion',
                  level: '3',
                  status: '1',
                  pid: '8',
                  ismenu: '0',
                  update_time: '1670290107',
                  create_time: null
                }
              ]
            }
          ]
        },
        {
          id: '12',
          title: '管理员管理',
          icon: 'el-icon-s-custom',
          url: 'user',
          level: '1',
          status: '1',
          pid: '0',
          ismenu: '1',
          update_time: '1673313341',
          create_time: null,
          childlist: [
            {
              id: '13',
              title: '管理员列表',
              icon: 'el-icon-user',
              url: 'web_api/user_index',
              level: '2',
              status: '1',
              pid: '12',
              ismenu: '1',
              update_time: '1673313359',
              create_time: null,
              childlist: [
                {
                  id: '32',
                  title: '余额变动记录',
                  icon: null,
                  url: 'balance/change/record',
                  level: '3',
                  status: '1',
                  pid: '13',
                  ismenu: '0',
                  update_time: '1671088827',
                  create_time: '1671088827'
                }
              ]
            },
            {
              id: '14',
              title: '添加管理员',
              icon: null,
              url: 'web_api/add_user',
              level: '3',
              status: '1',
              pid: '12',
              ismenu: '0',
              update_time: '1670290206',
              create_time: null
            },
            {
              id: '15',
              title: '管理员修改',
              icon: null,
              url: 'web_api/update_user',
              level: '3',
              status: '1',
              pid: '12',
              ismenu: '0',
              update_time: '1670290324',
              create_time: null
            },
            {
              id: '16',
              title: '删除管理员',
              icon: null,
              url: 'web_api/del_user',
              level: '3',
              status: '1',
              pid: '12',
              ismenu: '0',
              update_time: null,
              create_time: null
            },
            {
              id: '29',
              title: '管理员子级账号',
              icon: null,
              url: 'administrator/sublevel',
              level: '2',
              status: '1',
              pid: '12',
              ismenu: '0',
              update_time: '1671089269',
              create_time: '1670290492'
            },
            {
              id: '30',
              title: '管理员余额修改',
              icon: null,
              url: 'balance/modification',
              level: '2',
              status: '1',
              pid: '12',
              ismenu: '0',
              update_time: '1671089286',
              create_time: '1670290585'
            }
          ]
        },
        {
          id: '19',
          title: '商务合作',
          icon: 'el-icon-postcard',
          url: 'business',
          level: '1',
          status: '1',
          pid: '0',
          ismenu: '1',
          update_time: '1673313298',
          create_time: null,
          childlist: [
            {
              id: '20',
              title: '添加商务合作',
              icon: null,
              url: 'web_api/business',
              level: '3',
              status: '1',
              pid: '19',
              ismenu: '0',
              update_time: null,
              create_time: null
            },
            {
              id: '21',
              title: '商务合作列表',
              icon: 'el-icon-copy-document',
              url: 'web_api/businessList',
              level: '2',
              status: '1',
              pid: '19',
              ismenu: '1',
              update_time: '1673313323',
              create_time: null
            }
          ]
        },
        {
          id: '22',
          title: '号码申诉',
          icon: 'el-icon-phone',
          url: 'number/appeal',
          level: '1',
          status: '1',
          pid: '0',
          ismenu: '1',
          update_time: '1673313259',
          create_time: '1668754524',
          childlist: [
            {
              id: '28',
              title: '号码申诉列表',
              icon: 'el-icon-mobile',
              url: 'number/appealList',
              level: '2',
              status: '1',
              pid: '22',
              ismenu: '1',
              update_time: '1673313278',
              create_time: '1670219063',
              childlist: [
                {
                  id: '27',
                  title: '资料审核',
                  icon: null,
                  url: 'data/review',
                  level: '3',
                  status: '2',
                  pid: '28',
                  ismenu: '0',
                  update_time: '1670219168',
                  create_time: '1670218034'
                },
                {
                  id: '31',
                  title: '添加号码申诉',
                  icon: null,
                  url: 'number/appeal',
                  level: '3',
                  status: '2',
                  pid: '28',
                  ismenu: '0',
                  update_time: '1670290932',
                  create_time: '1670290932'
                }
              ]
            }
          ]
        },
        {
          id: '24',
          title: '用户余额变动',
          icon: 'el-icon-coin',
          url: 'balance/change',
          level: '1',
          status: '1',
          pid: '0',
          ismenu: '1',
          update_time: '1673313244',
          create_time: '1669621742'
        },
        {
          id: '34',
          title: '账号信息',
          icon: 'el-icon-setting',
          url: 'account/information',
          level: '1',
          status: '1',
          pid: '0',
          ismenu: '1',
          update_time: '1675995009',
          create_time: '1675994997'
        },
        {
          id: '37',
          title: '公告',
          icon: 'el-icon-chat-line-square',
          url: 'notice',
          level: '1',
          status: '1',
          pid: '0',
          ismenu: '1',
          update_time: '1677032298',
          create_time: '1677032291',
          childlist: [
            {
              id: '38',
              title: '添加公告',
              icon: 'el-icon-tickets',
              url: 'add/announcement',
              level: '2',
              status: '1',
              pid: '37',
              ismenu: '1',
              update_time: '1677032347',
              create_time: '1677032335'
            },
            {
              id: '39',
              title: '公告列表',
              icon: 'el-icon-printer',
              url: 'announcement/list',
              level: '2',
              status: '1',
              pid: '37',
              ismenu: '1',
              update_time: '1677032395',
              create_time: '1677032388'
            }
          ]
        }
      ]
      this.$store.commit('menuID/changeAccountPermission', res.data.info)
    },
    // 给请求头传id
    addMenuId (id) {
      // console.log(id, '点击的导航栏id')
      this.$store.commit('menuID/changeIsMenu', id)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
