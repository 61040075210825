import axios from 'axios'
import store from '@/store'
// import { Message } from 'element-ui'
// import router from '@/router'

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 10000
})

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  // config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
  const token = localStorage.getItem('token')
  if (token) {
    config.headers.token = token
  }
  const id = store.getters.ismenuId
  if (id) {
    config.headers.user_access_id = id
    // console.log(store.getters.ismenuId, '请求头的id')
  }
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  return response
}, function (error) {
  console.log(error)
  return Promise.reject(error)
})

export default instance
