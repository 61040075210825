import request from '@/utils/request'
// 登录
export const userLogin = (data) => {
  return request({
     baseURL:'/web_api',
    url: '/web_api/login',
    method: 'POST',
    data
  })
}
// 退出登录
export const LogOut = () => {
  return request({
     baseURL:'/web_api',
    url: '/web_api/quit',
    method: 'POST'
  })
}
