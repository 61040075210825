// 按钮权限递归封装
const ArrayUserButton = []
export function data (permission) {
  // console.log(permission, "permissionJS");
  // console.log(permission, "Js封装");
  for (let index = 0; index < permission.length; index++) {
    // console.log(permission[index].childlist, 11114444)
    if (permission[index].childlist && permission[index].childlist.length > 0) {
    //   console.log(permission[index].childlist, 'childlist')
      data(permission[index].childlist)
    }
    if (permission[index].ismenu === '0') {
      ArrayUserButton.push(permission[index].url)
    }
    // console.log(permission[index].ismenu, '猪嘎嘎')
    // console.log(ArrayUserButton, 'ArrayUserButton')
  }
  return ArrayUserButton
}
