<template>
    <div class="register-big">
      <!-- 上 -->
        <div class="register-item">
            <div class="numberBox">
                <span>大有科工码号卫士</span>
            </div>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
                <el-form-item prop="username">
                    <el-input placeholder='请输入用户名' v-model.trim="ruleForm.username"></el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input placeholder='请输入密码' show-password v-model.trim="ruleForm.password"></el-input>
                </el-form-item>
                <el-form-item prop="company_name">
                    <el-input placeholder='请输入公司名称' v-model.trim="ruleForm.company_name"></el-input>
                </el-form-item>
                <el-form-item prop="phone">
                    <el-input placeholder='请输入手机号' v-model.trim="ruleForm.phone"></el-input>
                </el-form-item>
                <el-form-item prop="email">
                    <el-input placeholder='请输入邮箱' v-model.trim="ruleForm.email"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button class="addRegister" type="primary" @click="userAddLogin">点击注册</el-button>
                </el-form-item>
                <div class="registerBox">
                    <p style="color:white;font-size:12px">已有账号?点击 <router-link to="/login" style="color:white">登录</router-link></p>
                </div>
            </el-form>
        </div>
        <!-- 下 -->
        <div class="login-bottom">
           <span>2022大有科工数字认证服务(北京)有限公司版权所有</span>
        <a href="https://beian.miit.gov.cn/#/Integrated/index" style="margin-left:8px;color:white">京ICP备2022008599号-3</a>
        <span>公安部备案号【鲁网信备 37021219361853300018号】</span>
        </div>
    </div>
</template>

<script>
import { Register } from '@/api/Register'

export default {
  name: 'my-login',
  data () {
    return {
      ruleForm: {
        username: '',
        password: '',
        company_name: '',
        phone: '',
        email: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { pattern: /^[A-Za-z0-9]{1,15}$/, message: '请输入1-15位字母、数字', trigger: ['blur'] }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { pattern: /^\S{6,15}$/, message: '密码必须是6-15的非空字符', trigger: ['blur'] }
        ],
        company_name: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  methods: {
    // 点击注册
    userAddLogin () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const data = new FormData()
          data.append('username', this.ruleForm.username)
          data.append('password', this.ruleForm.password)
          data.append('company_name', this.ruleForm.company_name)
          data.append('phone', this.ruleForm.phone)
          data.append('email', this.ruleForm.email)
          const { data: res } = await Register(data)
          //   console.log(res, '查看注册情况')
          if (res.returnCode === 0) {
            this.$message.success(res.msg)
            this.$router.push('/login')
          } else {
            this.$message(res.msg)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }

  }
}
</script>

<style lang="scss" scoped>
// 整个背景
.register-big{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2d3a4b;
    position: relative;
    //  border: 1px solid red;
    // background-color: rgba(10, 17, 17, 0.5);
    // “background-size: cover;图像铺满整个背景区域,图像下端或右端显示不完整,图像不会变形”
    // background-size:cover ;
}
.register-item{
    width: 450px;
    height: 450px;
    // border: 1px solid red;
    // background-color: rgba(18, 17, 17, 0.2);
    // border: 3px solid blue;
    // border: 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    .registerBox{
      // border: 2px solid yellow;
      width: 100%;
    }
}
.el-form{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    position: absolute;
    top: 50px;
}
.addRegister{
    width: 100%;
}
.el-form-item{
    width: 100%;
}

.numberBox{
    color: white;
    font-size: 25px;
    font-weight: 500;
    margin-top: 10px;
    // margin-bottom: 10px;
}

.login-bottom{
  position: absolute;
  bottom: 5px;
  color: white;
  font-size: 13px;
}
</style>
