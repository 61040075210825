import router from './router'
import { removeAllItem } from '@/utils/StoreData'
const whiteList = ['/login', '/404', '/401', '/register']

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token')
  console.log(token, '路由鉴权查看token是否存储')
  if (token) {
    if (to.path === '/login' || to.path === '/register') {
      next('/')
    } else {
      next()
    }
  } else {
    if (whiteList.includes(to.path)) {
      next()
    } else {
      next('/login')
      removeAllItem()
      location.reload()
    }
  }
})
