<template>
    <div class="register-big">
      <!-- 上 -->
        <div class="register-item">
            <div class="numberBox">
                <span>移动号码助手</span>
            </div>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
                <el-form-item prop="username">
                    <el-input placeholder='请输入用户名' v-model.trim="ruleForm.username"></el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input placeholder='请输入密码' show-password v-model.trim="ruleForm.password"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button class="addRegister" type="primary" @click="userAddLogin">登录</el-button>
                </el-form-item>
            </el-form>
            <div class="registerBox">
              <p style="color:white;font-size:12px">还没有账号?点击 <router-link to="/register" style="color:white">注册</router-link>  吧</p>
            </div>

        </div>
        <!-- 下 -->
        <div class="login-bottom">
           <span>移动号码助手</span>
        <a href="https://beian.miit.gov.cn/#/Integrated/index" style="margin-left:8px;color:white">京ICP备2022008599号-3</a>
        <span>公安部备案号【鲁网信备 37021219361853300018号】</span>
        </div>
    </div>
</template>

<script>
import { userLogin } from '@/api/userLogin'

export default {
  name: 'my-login',
  data () {
    return {
      ruleForm: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { pattern: /^[A-Za-z0-9]{1,15}$/, message: '请输入1-15位字母、数字', trigger: ['blur'] }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { pattern: /^\S{6,15}$/, message: '密码必须是6-15的非空字符', trigger: ['blur'] }
        ]
      }
    }
  },
  methods: {
    // 点击登录
    async userAddLogin () {
      const data = new FormData()
      data.append('username', this.ruleForm.username)
      data.append('password', this.ruleForm.password)
      const { data: res } = await userLogin(data)
      // console.log(res, '查看登录情况')
      if (res.returnCode === '000') {
        this.$message.success(res.msg)
        localStorage.setItem('token', res.data.token)
        localStorage.setItem('level', res.data.level)
        localStorage.setItem('apikey', res.data.apikey)
        localStorage.setItem('mch_id', res.data.mch_id)
        this.$router.push('/')
        localStorage.setItem('username', this.ruleForm.username)
      } else {
        this.$message.error(res.msg)
      }
    }

  }
}
</script>

<style lang="scss" scoped>
// 整个背景
.register-big{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2d3a4b;
    position: relative;
    //  border: 1px solid red;
    // background-color: rgba(10, 17, 17, 0.5);
    // “background-size: cover;图像铺满整个背景区域,图像下端或右端显示不完整,图像不会变形”
    // background-size:cover ;
}
.register-item{
    width: 450px;
    height: 250px;
    // border: 1px solid red;
    // background-color: rgba(18, 17, 17, 0.2);
    // border: 3px solid blue;
    // border: 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    .registerBox{
      // border: 2px solid yellow;
      width: 80%;
    }
}
.el-form{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    position: absolute;
    top: 50px;
}
.addRegister{
    width: 100%;
}
.el-form-item{
    width: 100%;
}

.numberBox{
    color: white;
    font-size: 25px;
    font-weight: 500;
    margin-top: 10px;
    // margin-bottom: 10px;
}

.login-bottom{
  position: absolute;
  bottom: 5px;
  color: white;
  font-size: 13px;
}
</style>
