import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login'
import Register from '../views/register'
import Layout from '../layOut'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    component: Login
  },
  {
    path: '/register',
    component: Register
  },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'web_api/sign_search',
        component: () => import('../views/InquiryNumber'),
        meta: {
          title: '标记查询',
          url: 'web_api/sign_search'
        }
      },
      {
        path: 'web_api/add_tel',
        component: () => import('../views/monitoringNumber'),
        meta: {
          title: '批量导入',
          url: 'web_api/add_tel'
        }
      },
      {
        path: 'web_api/user_index',
        component: () => import('../views/administrators'),
        meta: {
          title: '管理员账号',
          url: 'web_api/user_index'
        }
      },
      {
        path: 'web_api/businessList',
        component: () => import('../views/businessContacts'),
        meta: {
          title: '商务联系',
          url: 'web_api/businessList'
        }
      },
      {
        path: 'role/roleList',
        component: () => import('../views/roleList'),
        meta: {
          title: '角色列表',
          url: 'role/roleList'
        }
      },
      {
        path: 'role/accessList',
        component: () => import('../views/permissionList'),
        meta: {
          title: '权限列表',
          url: 'role/accessList'
        }
      },
      {
        path: 'home',
        component: () => import('../views/homePage'),
        meta: {
          title: '首页',
          url: 'home'
        }
      },
      {
        path: 'number/appealList',
        component: () => import('../views/numberAppeal'),
        meta: {
          title: '号码申诉',
          url: 'number/appealList'
        }
      },
      {
        path: 'balance/change',
        component: () => import('../views/balanceChange'),
        meta: {
          title: '用户余额变动',
          url: 'balance/change'
        }
      },
      {
        path: 'web_api/ov_sign_search',
        component: () => import('../views/ovTagQuery'),
        meta: {
          title: 'ov标记查询',
          url: 'web_api/ov_sign_search'
        }
      },
      {
        path: 'account/information',
        component: () => import('../views/accountInformation'),
        meta: {
          title: '账号信息',
          url: 'account/information'
        }
      },
      // 添加公告
      {
        path: 'add/announcement',
        component: () => import('../views/notice'),
        meta: {
          title: '添加公告',
          url: 'add/announcement'
        }
      },
      // 公告列表
      {
        path: 'announcement/list',
        component: () => import('../views/announcementList'),
        meta: {
          title: '公告列表',
          url: 'announcement/list'
        }
      },
      // 公告列表详情1
      {
        path: 'announcement/listOne',
        component: () => import('../views/announcementList/announcementListOne')
      },
      // 全网标记检测
      {
        path: 'markingDetails',
        component: () => import('../views/markingDetails'),
        meta: {
          title: '全网标记检测',
          url: 'markingDetails'
        }
      },
      // 企业号码名片
      {
        path: 'BusinessCard',
        component: () => import('../views/BusinessCard'),
        meta: {
          title: '号码认证-电话名片',
          url: 'BusinessCard'
        }
      },
      // 短信风险号码验证
      {
        path: 'SMSNumberVerification',
        component: () => import('../views/SMSNumberVerification'),
        meta: {
          title: '短信风险号码验证',
          url: 'SMSNumberVerification'
        }
      },
      // 号码来电名片
      {
        path: 'order/orderList',
        component: () => import('../views/orderList'),
        meta: {
          title: '订单列表',
          url: 'order/orderList'
        }
      },
      {
        path: 'order/customList',
        component: () => import('../views/customList'),
        meta: {
          title: '客户展示',
          url: 'order/customList'
        }
      },
      {
        path: 'order/dataList',
        component: () => import('../views/dataList'),
        meta: {
          title: '数据管理',
          url: 'order/dataList'
        }
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
