<template>
    <div>

        <NavMenu></NavMenu>
    </div>
</template>

<script>
import NavMenu from './NavMenu.vue'
export default {
  components: { NavMenu },
  name: 'my-aside',
  data () {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
.aside-top{
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid black;
  border-top: 2px solid black;
  color: white;
  margin-top: 8px;
  margin-bottom: 20px;
  height: 80px;
}
img{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 5px;
}
</style>
