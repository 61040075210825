import Vue from 'vue'
import Vuex from 'vuex'
import menuID from './module/menuID'
import tags from './module/tags'
import submenu from './module/submenu'
import { getters } from './getter'
Vue.use(Vuex)

export default new Vuex.Store({
  getters,
  modules: {
    menuID, tags, submenu
  }
})
