import { setItem, getItem } from '@/utils/StoreData'

export default {
  namespaced: true,
  state: {
    tags: getItem('tags') || [{ title: '首页', path: '/home' }]
  },
  mutations: {
    // 添加tags
    addTag (state, tags) {
      if (state.tags.length >= 6) {
        // console.log(state.tags.length, '长度')
        state.tags.splice(1, 1)
      }
      // console.log(state, 'state')
      // console.log(tags, 'tags')
      const hasTag = state.tags.find(item => item.path === tags.path)
      // console.log(hasTag, 'hasTag')
      if (!hasTag) {
        state.tags.push(tags)
        setItem('tags', state.tags)
        if (state.tags.length === 6) {
          state.tags.splice(1, 1)
        }
      }
    },
    // 删除tags
    removeTag (state, index) {
      state.tags.splice(index, 1)
      setItem('tags', state.tags)
    },
    // 清除所有tags
    removeAllTag (state) {
      state.tags = [{ title: '首页', path: '/home' }]
      setItem('tags', state.tags)
    }
  },
  actions: {
    addTag ({ commit }, tag) {
      commit('addTag', tag)
    },
    removeTag ({ commit }, index) {
      commit('removeTag', index)
    },
    removeAllTag ({ commit }) {
      commit('removeAllTag')
    }
  }
}
