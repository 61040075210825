<template>
    <div class="header-big">
      <div class="leftBox">
        <i class="el-icon-s-unfold" @click="addIconRight" v-if="!this.$store.getters.isSubmenu"></i>
        <i class="el-icon-s-fold" @click="addIconLeft" v-else></i>

          <el-breadcrumb separator="/" style="margin-left:20px">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>{{routingData}}</el-breadcrumb-item>
          </el-breadcrumb>
      </div>
      <!-- 头部右侧 -->
      <div class="header-right">
        <!-- 三目运算 展示对应ico -->
   <i :class=" this.iFullScreen? 'el-icon-copy-document headerIco': ' el-icon-full-screen headerIco'"
       title="全屏/退出全屏"
       @click="toggleFullScreen" >
   </i>

        <i class="el-icon-refresh" @click="addRenovate"></i>
        <i class="el-icon-switch-button" @click="tuiUser"></i>
        <div>用户名: <span style="font-weight:600">{{username}}</span></div>

      </div>
    </div>
</template>

<script>
// 引入全屏插件
import screenfull from 'screenfull'
import { LogOut } from '@/api/userLogin'
// import { removeItem, removeAllItem } from '@/utils/StoreData'

export default {
  name: 'my-header',
  data () {
    return {
      // 全屏变量
      iFullScreen: false,
      // 用户名
      username: localStorage.getItem('username'),
      // 路由数据
      routingData: localStorage.getItem('bread')
    }
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        // console.log(val.meta)
        if (val.meta.title === '首页' && val.meta === {}) {
          this.routingData = ''
          return
        }
        this.routingData = val.meta.title
        localStorage.setItem('bread', this.routingData)
      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {
    // 退出登录
    tuiUser () {
      this.$confirm('确定要退出吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { data: res } = await LogOut()
        console.log(res, '退出登录查看')
        if (res.returnCode === '000' || res.returnCode === 1002) {
          this.$router.push('/login')
          localStorage.clear()
          location.reload()
          this.$message({
            type: 'success',
            message: '退出成功!'
          })
        } else {
          this.$message.error(res.msg)
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消退出'
        })
      })
    },
    // 点击左侧小图标
    addIconRight () {
      this.$store.commit('submenu/SET_SUBMENU', true)
    },
    // 点击向右的图标
    addIconLeft () {
      this.$store.commit('submenu/SET_SUBMENU', false)
    },
    // 页面全屏事件
    toggleFullScreen () {
      // 判断当前浏览器是否支持全屏
      if (screenfull.isEnabled) {
        // 当前页面是否全屏
        if (!screenfull.isFullscreen) {
          this.iFullScreen = !this.iFullScreen
          screenfull.request()
        } else {
          this.iFullScreen = !this.iFullScreen
          screenfull.exit()
        }
      } else {
      // 提醒 无法全屏浏览
        this.$message({ message: '你的浏览器不支持全屏', type: 'warning' })
      }
    },

    // 点击刷新按钮
    addRenovate () {
      location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
.header-big{
  // border: 1px solid red;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .leftBox{
    display: flex;
    align-items: center;
    padding-left: 10px;
    // 点击左右菜单栏伸缩延申
    .el-icon-s-fold,.el-icon-s-unfold{
      cursor: pointer;
    }
    // border: 1px solid red;
  }

.header-right{
  // border: 1px solid red;
  width: 220px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-right: 10px;
  // 全屏按钮
  .el-icon-full-screen,.el-icon-refresh,.el-icon-switch-button{
    cursor: pointer;
  }
}
}
</style>
