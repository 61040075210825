<template>
    <div>
        <el-menu
          :default-active="$route.path"
          class="el-menu-vertical-demo"
          background-color="#2d3a4b"
          text-color="#fff"
          unique-opened
          router
          active-text-color="#3C9EFF"
          collapse-transition
          :collapse="!isShow"
          :style="{width:!$store.getters.isSubmenu ? '50px' : '200px'}"
          >
          <div class="submenuBox" v-if="$store.getters.isSubmenu">码号卫士</div>
          <submenu></submenu>

        </el-menu>
    </div>
</template>

<script>
import submenu from './submenu.vue'
export default {
  components: { submenu },
  name: 'nav-menu',
  computed: {
    isShow () {
      return this.$store.getters.isSubmenu
    }
  }

}
</script>

<style lang="scss" >
.el-menu{
    border-right: 1px solid #2d3a4b;
}
.submenuBox{
  background-color: #2f3031;
  height: 30px;
  color: white;
 display: flex;
 align-items: center;
 justify-content: center;
 font-size: 15px;
}
.el-menu--collapse  .el-submenu__title span{
  display: none;
}

.el-menu--collapse  .el-submenu__title .el-submenu__icon-arrow{
  display: none;
}

</style>
