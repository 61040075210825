import { getItem, setItem } from '@/utils/StoreData'
import { data } from '@/utils/PonRef'
export default {
  namespaced: true,
  state: {
    // 点击左侧导航栏传递id
    isMenuId: getItem('user_access_id') || '',
    // 用户权限
    permission: getItem('userPermission') || ''
  },
  mutations: {
    // 点击左侧导航栏传递id
    changeIsMenu (state, id) {
      state.isMenuId = id
      setItem('user_access_id', id)
      // console.log(id, 'vuex里的')
    },
    // 当前该账号 权限
    changeAccountPermission (state, permission) {
      // console.log(permission, '213123')
      setItem('userPermission', data(permission))
      state.permission = data(permission)
      // console.log(state.permission, '权限按钮路径')
    }
  },
  actions: {

  }
}
