import request from '@/utils/request'
// 注册
export const Register = (data) => {
  return request({
     baseURL:'/web_api',
    url: '/web_api/register',
    method: 'POST',
    data
  })
}
