<template>

        <el-container>
          <el-aside width="auto"><Aside></Aside></el-aside>
          <el-container>
            <el-header><Header></Header></el-header>
            <el-main><Main></Main></el-main>
          </el-container>
        </el-container>
</template>

<script>
import Aside from './Aside.vue'
// import Footer from './Footer.vue'
import Header from './Header.vue'
import Main from './Main.vue'
export default {
  name: 'layout-my',
  components: { Header, Aside, Main }

}
</script>

<style lang="scss" scoped>

.el-container{
    height: 100%;
}

.el-header{
    background-color: white;
    color: #333;
    padding: 0 0;
    border-bottom: 2px solid rgb(222, 221, 221);
  }
  // .el-footer{
  //   background-color: #ffffff;
  //   color: #333;
  //   height: 30px !important;
  //   // border: 1px solid red;
  // }

  .el-aside {
    background-color: #2d3a4b;
    color: #333;
    border-right: 0;
  }

  .el-main {
    background-color: rgb(248, 248, 248);
    color: #333;
  }
</style>
